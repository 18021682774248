
import Vue from 'vue'
import RPHBadge from 'rph/atoms/RPHBadge/index.vue'

export default Vue.extend({
  name: 'RPHTabBarItem',
  components: { RPHBadge },
  props: {
    to: { type: [String, Object], required: true },
    handler: { type: Boolean, default: false },
    isBasket: { type: Boolean, default: false },
  },
  data() {
    return {}
  },
  computed: {
    // products(): any {
    //   return this.$store.getters['rph-basket/productsPrice']
    // },
    // count(): number {
    //   return this.$services.basket.calc.count(this.products)
    // },

    listOfProducts(): any {
      return this.$store.getters['rph-basket/basketList']
    },
    basketItemsCount(): any {
      return this.listOfProducts.reduce((acc: number, item: any) => {
        return (acc += item.count)
      }, 0)
    },
    count(): number {
      return this.$store.getters['rph-basket/totalCount']
    },
  },
  methods: {},
})
